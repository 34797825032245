<template>
  <div class="fonPlanet " id="fonPlanet">
    <div class=" px-3 " style="padding-top:200px;">
      <div class="cs-loader">
        <div class="cs-loader-inner">
          <label>●</label>
          <label>●</label>
          <label>●</label>
          <label>●</label>
          <label>●</label>
          <label>●</label>
        </div>
      </div>
      <h1 class="white-text ">Авторизация</h1>
    </div>
  </div>
</template>

<script type="text/javascript">
  export default {
    created(){
      localStorage.removeItem("glxtoken")
      let token
      let debug = document.location.href.indexOf("localhost") > -1
      if (debug)
        token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAsIm5hbWUiOiJ0b3JyaGlubyIsImlhdCI6MTYyNDI2MzIxMiwiZXhwIjoxNjI2ODU1MjEyfQ.MhP0CMnwOXdue0-Hd6qOu3UXsz9bLyviaGRc1yJ597o"
      else
        token = this.getCookie("glxtoken")
      console.log(token)
      if (token == "undefined" || token == undefined || token == null || token == ''){
        console.log("нет токена")
        document.location.href = 'https://warforgalaxy.com/login?return=http://cc.warforgalaxy.com'
      }

      this.$axios.post("app/enter",{}, {headers: {"token": token}}).then(res=>{
        // console.log();
        if(res.status != 200){
          console.log(res)
          document.location.href = 'https://warforgalaxy.com/login?return=http://cc.warforgalaxy.com'
        }else
          console.log("Done")
        localStorage.setItem("glxtoken", token)
        this.$router.go(-1)
        this.$router.push({ path: '/' }).catch(() => {})
      }).catch(err => {
        console.log(err.response);
        if (err.response.status == 401 && !debug) {
          document.location.href = 'https://warforgalaxy.com/login?return=http://cc.warforgalaxy.com'
        }
        // document.location.href = 'https://warforgalaxy.com/login?return=http://cc.warforgalaxy.com'
      })
    },
    methods:{
      getCookie(name) {
        let matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([.$?*|{}()[]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
        return matches ? decodeURIComponent(matches[1]) : undefined;
      },
    },
    data(){
      return{

      }
    }
  }
</script>

<style media="screen" scoped="">
h1{
  color: var(--login-h1-color);
  text-align: center;
  width: 100%;
}
.fonPlanet {
  /* box-shadow: 0 0 85px #000; */
  /* padding-top: 30px; */
  position: relative;
  background: var(--login-fonplanet-bg);
  height: 100vh;
  max-height: 100vh;
  width: 100%;
  filter: blur();
}

/* ****************************************** */

.square{
  border:0;
  width:80px;
  padding:0px;
  margin-left: calc(50% - 40px);
  margin-left: -webkit-calc(50% - 40px);
  margin-left: -moz-calc(50% - 40px);
}

.spin {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  border:dashed 4px white;
  -webkit-animation-name: spin;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: cubic-bezier(0, 0, 0, 1.27);
}

@-webkit-keyframes spin {
  from   {  -webkit-transform: rotate(0deg); }
  to   {  -webkit-transform: rotate(360deg); }
}

/* ****************************************** */
.cs-loader {
  height: 20px;
  width: 100%;
}

.cs-loader-inner {
  transform: translateY(-50%);
  /* top: 50%; */
  /* position: absolute; */
  width: calc(100%);
  color: var(--login-cs-loader-inner-color);
  padding: 0 100px;
  text-align: center;
}

.cs-loader-inner label {
  font-size: 50px;
  opacity: 0;
  display:inline-block;
}

@keyframes lol {
  0% {
    opacity: 0;
    transform: translateX(-300px);
  }
  33% {
    opacity: 1;
    transform: translateX(0px);
  }
  66% {
    opacity: 1;
    transform: translateX(0px);
  }
  100% {
    opacity: 0;
    transform: translateX(300px);
  }
}

@-webkit-keyframes lol {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-300px);
  }
  33% {
    opacity: 1;
    -webkit-transform: translateX(0px);
  }
  66% {
    opacity: 1;
    -webkit-transform: translateX(0px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(300px);
  }
}

.cs-loader-inner label:nth-child(6) {
  -webkit-animation: lol 3s infinite ease-in-out;
  animation: lol 3s infinite ease-in-out;
}

.cs-loader-inner label:nth-child(5) {
  -webkit-animation: lol 3s 100ms infinite ease-in-out;
  animation: lol 3s 100ms infinite ease-in-out;
}

.cs-loader-inner label:nth-child(4) {
  -webkit-animation: lol 3s 200ms infinite ease-in-out;
  animation: lol 3s 200ms infinite ease-in-out;
}

.cs-loader-inner label:nth-child(3) {
  -webkit-animation: lol 3s 300ms infinite ease-in-out;
  animation: lol 3s 300ms infinite ease-in-out;
}

.cs-loader-inner label:nth-child(2) {
  -webkit-animation: lol 3s 400ms infinite ease-in-out;
  animation: lol 3s 400ms infinite ease-in-out;
}

.cs-loader-inner label:nth-child(1) {
  -webkit-animation: lol 3s 500ms infinite ease-in-out;
  animation: lol 3s 500ms infinite ease-in-out;
}
/* ****************************************** */
</style>
